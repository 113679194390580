<template>
<b-card no-body>
  <validation-observer
    v-slot="{ handleSubmit }" ref="VForm"
  >
    <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD('VForm', $refs))">
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">{{ $parent.isAdd?'Tambah':'Edit' }} {{ $parent.pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col lg="8">
            <b-row>
              <b-col lg="6">
                <b-form-group>
                  <label>Kode<span class="text-danger mr5">*</span></label>
                  <b-form-input type="text" class="form-control" v-model="row.mlabd_code" placeholder="e.g 0001" :formatter="$parent.code" />
                  <VValidate 
                    name="Kode" 
                    v-model="row.mlabd_code" 
                    :rules="toValidate(mrValidation.mlabd_code)"
                  />
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <b-form-group>
                  <label>Nama<span class="text-danger mr5">*</span></label>
                  <b-form-input :formatter="$parent.normalText" placeholder="Nama" type="text" class="form-control" v-model="row.mlabd_name"/>
                  <VValidate 
                    name="Nama" 
                    v-model="row.mlabd_name" 
                    :rules="toValidate(mrValidation.mlabd_name)"
                  />
                </b-form-group>
              </b-col>

               <b-col lg="6">
                <b-form-group>
                  <label>Satuan<span class="text-danger mr5">*</span></label>
                  <b-form-input :formatter="$parent.normalText" placeholder="Satuan" type="text" class="form-control" v-model="row.mlabd_satuan"/>
                  <VValidate 
                    name="Satuan" 
                    v-model="row.mlabd_satuan" 
                    :rules="toValidate(mrValidation.mlabd_satuan)"
                  />
                </b-form-group>
              </b-col>
              
              <b-col lg="6">
                <b-form-group>
                  <label>Merk<span class="text-danger mr5">*</span></label>
                  <b-form-input :formatter="$parent.normalText" placeholder="Merk" type="text" class="form-control" v-model="row.mlabd_merk"/>
                  <VValidate 
                    name="Merk" 
                    v-model="row.mlabd_merk" 
                    :rules="toValidate(mrValidation.mlabd_merk)"
                  />
                </b-form-group>
              </b-col>

              
              
              <b-col lg="6">
                <b-form-group>
                    <label>Pilih Kategori<span class="text-danger mr5">*</span></label>
                    <v-select 
                        @input="changeKategori($event)"
                        :options="$parent.mLogistikKategori"
                        v-model="row.mlabd_mlk_id"
                        placeholder="Pilih Kategori"
                        label="text"
                    :clearable="true" :reduce="v=>v.value"
                    >
                    </v-select>
                  
                    <VValidate 
                        name="Kategori" 
                        v-model="row.mlabd_mlk_id" 
                        :rules="toValidate(mrValidation.mlabd_mlk_id)"
                    />
                </b-form-group>
              </b-col>
              
              <b-col lg="6">
                <b-form-group>
                    <label>Pilih Bidang<span class="text-danger mr5">*</span></label>
                    <v-select 
                        @input="changeBidang($event)"
                        :options="$parent.mLogistikAsetBidang"
                        v-model="row.mlabd_mlab_id"
                        placeholder="Pilih Bidang"
                        label="text"
                    :clearable="true" :reduce="v=>v.value"
                    >
                    </v-select>
                  
                    <VValidate 
                        name="Bidang" 
                        v-model="row.mlabd_mlab_id" 
                        :rules="toValidate(mrValidation.mlabd_mlab_id)"
                    />
                </b-form-group>
              </b-col>
              
              <b-col lg="6">
                <b-form-group>
                    <label>Pilih Barang<span class="text-danger mr5">*</span></label>
                    <v-select 
                        :options="$parent.mLogistikAsetBarang"
                        v-model="row.mlabd_mlabar_id"
                        placeholder="Pilih Barang"
                        label="text"
                    :clearable="true" :reduce="v=>v.value"
                    >
                    </v-select>
                  
                    <VValidate 
                        name="Barang" 
                        v-model="row.mlabd_mlabar_id" 
                        :rules="toValidate(mrValidation.mlabd_mlabar_id)"
                    />
                </b-form-group>
              </b-col>
              
              <b-col lg="6">
                <b-form-group class="mt-3">
                  <label>Status <span class="text-danger mr5">*</span></label>
                  <b-form-radio-group
                    :options="Config.mr.StatusOptions"
                    v-model="row[$parent.statusKey]"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col lg="12" class="text-right">
            <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default mr-3">Batal</button>
            <b-button type="submit" variant="primary" class="btn-rounded">Simpan</b-button>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-form>
  </validation-observer>
</b-card>
</template>

<script>
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'

export default{
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object
  },
  methods: {
    toValidate(val){
      return {...val}
    },
    changeKategori(e){
      this.row.mlabd_mlab_id = null
      this.row.mlabd_mlabar_id = null
      
      this.$parent.loadingOverlay = true
      Gen.apiRest(
        "/do/"+this.$parent.modulePage,
        {data:{type:'change-kategori',id: e}}, 
        "POST"
      ).then(res=>{
        this.$parent.mLogistikAsetBidang = res.data.mLogistikAsetBidang
        this.$parent.loadingOverlay = false
      })
    },
    changeBidang(e){
      this.$parent.loadingOverlay = true
      this.row.mlabd_mlabar_id = null
      Gen.apiRest(
        "/do/"+this.$parent.modulePage,
        {data:{type:'change-bidang',id: e}}, 
        "POST"
      ).then(res=>{
        this.$parent.mLogistikAsetBarang = res.data.mLogistikAsetBarang
        this.$parent.loadingOverlay = false
      })
    }
  },
}
</script>